import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { User, UserDealer } from "../../../shared/interfaces/user.interface";

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Set User': props<{ payload: { usernameOrEmail: string; password: string } }>(),
    'Set User Success': props<{ data: User }>(),
    'Set User Failure': props<{ error: unknown }>(),

    'Reset User Password': props<{ payload: { data: string} }>(),
    'Reset User Password Success': props<{ data: unknown }>(),
    'Reset User Password Failure': props<{ error: unknown }>(),

    'Load User Dealers': emptyProps(),
    'Load User Dealers Success': props<{ data: UserDealer[] }>(),
    'Load User Dealers Failure': props<{ error: unknown }>(),

    'Change User Dealer': props<( { payload: { usersDealersId: number}})>(),
    'Change User Dealer Success': props<{ data: User }>(),
    'Change User Dealer Failure': props<{ error: unknown }>(),

    'Unset User': emptyProps()
  }
})
